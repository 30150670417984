import React, { useState, useEffect } from "react";
import axios from "axios";
import confetti from "canvas-confetti";
import {
  X,
  Trophy,
  Crown,
  Medal,
  Award,
  BookOpen,
  PenTool,
  CheckCircle,
  XCircle,
} from "lucide-react";

const LeaderboardCard = ({ data, currentUserId }) => (
  <Card className="mt-4">
    <h2 className="text-2xl font-bold mb-4">Company Leaderboard</h2>
    <div className="space-y-2">
      {data.map((user, index) => (
        <div
          key={user.id}
          className={`flex justify-between items-center p-2 rounded-lg ${
            user.id === currentUserId ? "bg-blue-100" : "bg-gray-100"
          }`}
        >
          <span className="font-semibold">
            {index + 1}. {user.name}
          </span>
          <span className="text-[#e61937]">Streak: {user.streak}</span>
        </div>
      ))}
    </div>
  </Card>
);

const LeaderboardModal = ({ isOpen, onClose, data, currentUserId }) => (
  <div className={`fixed inset-0 z-50 ${isOpen ? "block" : "hidden"}`}>
    <div
      className="absolute inset-0 bg-black opacity-50"
      onClick={onClose}
    ></div>
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-2xl p-8 w-11/12 max-w-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-[#e61937]">
          Leaderboard Champions
        </h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
      </div>
      <div className="space-y-4">
        {data.map((user, index) => (
          <div
            key={user.id}
            className={`flex justify-between items-center p-4 rounded-lg ${
              user.id === currentUserId
                ? "bg-blue-100 border-2 border-blue-500"
                : index === 0
                ? "bg-yellow-100"
                : index === 1
                ? "bg-gray-100"
                : index === 2
                ? "bg-amber-100"
                : "bg-white"
            } transition-all duration-300 transform hover:scale-105`}
          >
            <div className="flex items-center">
              <span
                className={`text-2xl font-bold flex items-center justify-center w-10 h-10 rounded-full ${
                  index === 0
                    ? "bg-yellow-500 text-white"
                    : index === 1
                    ? "bg-gray-300 text-gray-800"
                    : index === 2
                    ? "bg-amber-600 text-white"
                    : "bg-gray-200 text-gray-800"
                } mr-3`}
              >
                {index === 0 ? <Crown size={24} /> : index + 1}
              </span>
              <div>
                <span className="font-semibold">{user.name}</span>
                {user.id === currentUserId && (
                  <span className="ml-2 text-blue-500 font-bold">(You)</span>
                )}
              </div>
            </div>
            <div className="flex items-center">
              {index === 0 && (
                <Crown size={24} className="text-yellow-500 mr-2" />
              )}
              {index === 1 && (
                <Medal size={24} className="text-gray-400 mr-2" />
              )}
              {index === 2 && (
                <Award size={24} className="text-amber-600 mr-2" />
              )}
              <Trophy size={18} className="text-[#e61937] mr-2" />
              <span className="text-[#e61937] font-bold text-lg">
                {user.streak}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-center">
        <p className="text-sm text-gray-600">
          Keep learning to climb the ranks!
        </p>
        <button
          onClick={onClose}
          className="mt-4 px-6 py-2 bg-[#e61937] text-white font-bold rounded-lg shadow-md hover:bg-[#d11730] transition-all duration-300 hover:translate-y-[-2px]"
        >
          Back to Quiz
        </button>
      </div>
    </div>
  </div>
);

const Button = ({ onClick, children, className, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-6 py-3 bg-[#e61937] text-white font-bold rounded-lg shadow-md hover:bg-[#d11730] transition-all duration-300 ${
      disabled ? "opacity-50 cursor-not-allowed" : "hover:translate-y-[-2px]"
    } ${className}`}
  >
    {children}
  </button>
);

const Card = ({ children, className }) => (
  <div
    className={`bg-white rounded-xl shadow-lg p-8 border-l-4 border-[#e61937] transition-all duration-300 hover:shadow-xl ${className}`}
  >
    {children}
  </div>
);

const Input = ({ id, value, onChange, label, placeholder, type = "text" }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#e61937] focus:border-[#e61937] transition-all duration-300"
    />
  </div>
);

const TextArea = ({ id, value, onChange, label, placeholder }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    <textarea
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows="4"
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#e61937] focus:border-[#e61937] transition-all duration-300 resize-none"
    />
  </div>
);

const Checkbox = ({ id, checked, onChange, label }) => (
  <div className="flex items-center space-x-3 mb-3">
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
      className="w-5 h-5 text-[#e61937] focus:ring-[#e61937] border-gray-300 rounded transition-all duration-200 cursor-pointer"
    />
    <label
      htmlFor={id}
      className="text-sm text-gray-700 cursor-pointer select-none"
    >
      {label}
    </label>
  </div>
);

const ProgressBar = ({ current, total }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div
      className="bg-[#e61937] h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${(current / total) * 100}%` }}
    />
  </div>
);

const App = () => {
  const [step, setStep] = useState("userInfo");
  const [userInfo, setUserInfo] = useState({
    name: "",
    age: "",
    aboutMe: "",
  });
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [streakCount, setStreakCount] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [xp, setXp] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pushToken, setPushToken] = useState(null);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
  const [contentType, setContentType] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [articleContent, setArticleContent] = useState("");

  const topics = [
    { id: "present-simple", label: "Present Simple" },
    { id: "past-simple", label: "Past Simple" },
    { id: "present-continuous", label: "Present Continuous" },
    { id: "past-continuous", label: "Past Continuous" },
    { id: "present-perfect", label: "Present Perfect" },
    { id: "conditionals", label: "Conditionals" },
    { id: "passive-voice", label: "Passive Voice" },
    { id: "reported-speech", label: "Reported Speech" },
  ];

  const generateContent = async () => {
    setIsLoading(true);
    setError(null);
    const userHobbies = selectedHobbies.map(
      (h) => hobbies.find((hobby) => hobby.id === h).label
    );

    try {
      let prompt;
      if (contentType === "article") {
        prompt = `Generate an article of about 300 words related to ${selectedTopic}, incorporating interests like ${userHobbies.join(
          ", "
        )} if possible. The article should be suitable for ${
          userInfo.name
        }, age ${userInfo.age}. Their self-description: "${
          userInfo.aboutMe
        }". Follow the article with 3 multiple-choice comprehension questions. Format the response as a JSON object with 'article', and 'questions' properties. The 'questions' should be an array of objects, each with 'question', 'options' (an array of 4 choices), and 'correct' (the index of the correct answer in the options array) properties.`;
      } else {
        prompt = `Generate 5 fill-in-the-blank sentences focusing on ${selectedTopic}. Each sentence should have a blank where a word is missing, and provide 4 options to choose from. Try to incorporate themes related to ${userHobbies.join(
          ", "
        )} if possible. The content should be suitable for ${
          userInfo.name
        }, age ${userInfo.age}. Their self-description: "${
          userInfo.aboutMe
        }". Format the response as a JSON array of objects, each with 'sentence' (including a blank represented by '____'), 'options' (an array of 4 choices), and 'correct' (the index of the correct answer in the options array) properties.`;
      }

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content:
                "You are an English language tutor creating personalized learning content.",
            },
            {
              role: "user",
              content: prompt,
            },
          ],
          temperature: 0.7,
          max_tokens: 2000,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      let contentString = response.data.choices[0].message.content;
      contentString = contentString.replace(/```json\n|```/g, "");
      const generatedContent = JSON.parse(contentString);

      if (contentType === "article") {
        setQuizQuestions(generatedContent.questions);
        setArticleContent(generatedContent.article);
      } else {
        setQuizQuestions(generatedContent);
      }
    } catch (error) {
      console.error("Error generating content:", error);
      setError("Failed to generate content. Please try again.");
      // Set some default content here if the API call fail
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === "quiz" && contentType && selectedTopic) {
      generateContent();
    }
  }, [step, contentType, selectedTopic]);

  const fetchLeaderboardData = async () => {
    // In a real application, you would fetch this data from your backend
    // For this example, we'll use mock data
    const mockData = [
      { id: 1, name: "Jakub Novák", streak: 15 },
      { id: 2, name: "Sofia Kováčová", streak: 12 },
      { id: 3, name: "Samuel Horváth", streak: 10 },
      { id: 4, name: "Nina Vargová", streak: 8 },
      { id: 5, name: userInfo.name, streak: streakCount },
    ];
    setLeaderboardData(mockData);
  };

  useEffect(() => {
    if (showLeaderboard) {
      fetchLeaderboardData();
    }
  }, [showLeaderboard]);

  const toggleLeaderboard = () => {
    setIsLeaderboardOpen(!isLeaderboardOpen);
    if (!isLeaderboardOpen) {
      fetchLeaderboardData();
    }
  };

  const hobbies = [
    { id: "reading", label: "Reading 📚" },
    { id: "sports", label: "Sports 🏀" },
    { id: "music", label: "Music 🎵" },
    { id: "cooking", label: "Cooking 👨‍🍳" },
    { id: "gaming", label: "Gaming 🎮" },
    { id: "travel", label: "Traveling ✈️" },
    { id: "art", label: "Art 🎨" },
    { id: "technology", label: "Technology 💻" },
  ];

  const handleUserInfoChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.id]: e.target.value });
  };

  const handleHobbyToggle = (hobby) => {
    setSelectedHobbies((prev) =>
      prev.includes(hobby) ? prev.filter((h) => h !== hobby) : [...prev, hobby]
    );
  };

  const startQuiz = () => {
    if (selectedHobbies.length > 0) {
      setStep("quiz");
    }
  };

  const handleAnswer = (answerIndex) => {
    setSelectedAnswer(answerIndex);
    setShowFeedback(true);
    const currentQuestion = quizQuestions[currentQuestionIndex];
    const isCorrect = answerIndex === currentQuestion.correct;

    if (isCorrect) {
      setScore(score + 1);
      setStreakCount(streakCount + 1);
      const earnedXP = 10 + streakCount * 5; // Base 10 XP + bonus for streak
      setXp(xp + earnedXP);
      triggerConfetti("small");
    } else {
      setStreakCount(0);
    }

    setTimeout(() => {
      setShowFeedback(false);
      setSelectedAnswer(null);
      if (currentQuestionIndex + 1 < quizQuestions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setShowResult(true);
        triggerConfetti("large");
      }
    }, 2000);
  };

  const triggerConfetti = (size) => {
    if (size === "small") {
      confetti({
        particleCount: 50,
        spread: 60,
        origin: { y: 0.6 },
      });
    } else {
      const duration = 5 * 1000;
      const animationEnd = Date.now() + duration;

      const randomInRange = (min, max) => {
        return Math.random() * (max - min) + min;
      };

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);

        confetti({
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        });
        confetti({
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        });
      }, 250);
    }
  };

  const resetQuiz = () => {
    setStep("userInfo");
    setUserInfo({ name: "", age: "", aboutMe: "" });
    setSelectedHobbies([]);
    setCurrentQuestionIndex(0);
    setScore(0);
    setShowResult(false);
    setStreakCount(0);
    setXp(0);
    setQuizQuestions([]);
    setError(null);
    setContentType(null);
    setSelectedTopic(null);
    setArticleContent("");
  };

  const renderQuestion = () => {
    const currentQuestion = quizQuestions[currentQuestionIndex];
    if (!currentQuestion) return null;

    return (
      <div className="space-y-4">
        {contentType === "article" ? (
          <p className="font-bold text-lg">{currentQuestion.question}</p>
        ) : (
          <p className="text-lg">{currentQuestion.sentence}</p>
        )}
        <div className="grid grid-cols-2 gap-4">
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(index)}
              className={`p-4 text-center rounded-lg transition-all duration-300 ${
                selectedAnswer === index
                  ? showFeedback
                    ? index === currentQuestion.correct
                      ? "bg-green-500 text-white"
                      : "bg-red-500 text-white"
                    : "bg-blue-500 text-white"
                  : "bg-white border-2 border-gray-300 hover:border-blue-500"
              }`}
              disabled={showFeedback}
            >
              {option}
              {showFeedback && (
                <span className="ml-2">
                  {index === currentQuestion.correct ? (
                    <CheckCircle className="inline-block" />
                  ) : (
                    <XCircle className="inline-block" />
                  )}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch (step) {
      case "quiz":
        if (isLoading) {
          return (
            <Card>
              <h2 className="text-2xl font-bold mb-4">
                Generating Your Content
              </h2>
              <p className="text-lg mb-4">
                Please wait while we prepare your personalized {contentType}...
              </p>
              <div className="loader"></div>
            </Card>
          );
        }
        if (error) {
          return (
            <Card>
              <h2 className="text-2xl font-bold mb-4">Error</h2>
              <p className="text-lg mb-4">{error}</p>
              <Button onClick={resetQuiz} className="w-full">
                Try Again
              </Button>
            </Card>
          );
        }
        if (showResult) {
          return (
            <Card>
              <h2 className="text-3xl font-bold mb-6 text-center">
                Quiz Results
              </h2>
              <div className="text-center mb-8">
                <p className="text-6xl font-bold text-[#e61937] mb-2">
                  {score}/{quizQuestions.length}
                </p>
                <p className="text-xl">Great job!</p>
              </div>
              <div className="bg-blue-100 rounded-lg p-4 mb-6">
                <p className="text-lg font-semibold">
                  XP Earned:{" "}
                  <span className="text-2xl text-blue-600">{xp}</span>
                </p>
                <p className="text-sm text-gray-600">
                  Keep learning to level up!
                </p>
              </div>
              <Button onClick={resetQuiz} className="w-full">
                Start New Quiz
              </Button>
            </Card>
          );
        }
        return (
          <Card>
            <h2 className="text-2xl font-bold mb-4">
              {contentType === "article"
                ? "Read and Comprehend"
                : "Fill in the Blank"}
            </h2>
            {contentType === "article" && (
              <div className="mb-6 p-4 bg-gray-100 rounded-lg max-h-60 overflow-y-auto">
                <p>{articleContent}</p>
              </div>
            )}
            <ProgressBar
              current={currentQuestionIndex + 1}
              total={quizQuestions.length}
            />
            {renderQuestion()}
            <div className="mt-4 flex justify-between items-center">
              <p className="text-lg font-semibold">
                Score: {score}/{quizQuestions.length}
              </p>
              <p className="text-lg font-semibold">Streak: {streakCount} 🔥</p>
            </div>
          </Card>
        );
      case "userInfo":
        return (
          <Card>
            <h2 className="text-2xl font-bold mb-4">Tell Us About Yourself</h2>
            <Input
              id="name"
              value={userInfo.name}
              onChange={handleUserInfoChange}
              label="Your Name"
              placeholder="Enter your name"
            />
            <Input
              id="age"
              value={userInfo.age}
              onChange={handleUserInfoChange}
              label="Your Age"
              placeholder="Enter your age"
              type="number"
            />
            <TextArea
              id="aboutMe"
              value={userInfo.aboutMe}
              onChange={handleUserInfoChange}
              label="About Me"
              placeholder="Tell us a bit about yourself..."
            />
            <Button onClick={() => setStep("hobbies")} className="w-full mt-4">
              Next
            </Button>
          </Card>
        );
      case "hobbies":
        return (
          <Card>
            <h2 className="text-2xl font-bold mb-4">Select Your Hobbies</h2>
            <div className="grid grid-cols-2 gap-2 mb-4">
              {hobbies.map((hobby) => (
                <Checkbox
                  key={hobby.id}
                  id={hobby.id}
                  checked={selectedHobbies.includes(hobby.id)}
                  onChange={() => handleHobbyToggle(hobby.id)}
                  label={hobby.label}
                />
              ))}
            </div>
            <Button onClick={() => setStep("contentChoice")} className="w-full">
              Next
            </Button>
          </Card>
        );
      case "contentChoice":
        return (
          <Card>
            <h2 className="text-2xl font-bold mb-4">
              Choose Your Learning Style
            </h2>
            <div className="space-y-4">
              <Button
                onClick={() => {
                  setContentType("article");
                  setStep("topicChoice");
                }}
                className="w-full flex items-center justify-center"
              >
                <BookOpen className="mr-2" /> Read an Article
              </Button>
              <Button
                onClick={() => {
                  setContentType("quiz");
                  setStep("topicChoice");
                }}
                className="w-full flex items-center justify-center"
              >
                <PenTool className="mr-2" /> Fill-in-the-Blank Quiz
              </Button>
            </div>
          </Card>
        );
      case "topicChoice":
        return (
          <Card>
            <h2 className="text-2xl font-bold mb-4">
              Choose a Topic to Practice
            </h2>
            <div className="grid grid-cols-2 gap-2">
              {topics.map((topic) => (
                <Button
                  key={topic.id}
                  onClick={() => {
                    setSelectedTopic(topic.id);
                    setStep("quiz");
                  }}
                  className="text-sm"
                >
                  {topic.label}
                </Button>
              ))}
            </div>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="flex items-center justify-center mb-6">
          <img
            src="https://www.oktava.sk/assets/images/oktava-main-red-184x184.webp"
            alt="Oktava Academy Logo"
            className="w-16 h-16 mr-3"
          />
          <h1 className="text-3xl font-bold text-[#e61937]">Oktava Academy</h1>
        </div>
        {renderStep()}
        {pushToken && (
          <p className="mt-4 text-sm text-gray-500 text-center">
            Push notifications enabled
          </p>
        )}
      </div>

      {/* Floating Action Button for Leaderboard */}
      <button
        onClick={toggleLeaderboard}
        className="fixed bottom-6 right-6 bg-[#e61937] text-white p-4 rounded-full shadow-lg hover:bg-[#d11730] transition-all duration-300 transform hover:scale-110"
      >
        <Trophy size={24} />
      </button>

      {/* Leaderboard Modal */}
      <LeaderboardModal
        isOpen={isLeaderboardOpen}
        onClose={() => setIsLeaderboardOpen(false)}
        data={leaderboardData}
        currentUserId={5}
      />
    </div>
  );
};

export default App;
